@import 'src/styles/_variables.module';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  padding: 30px 0 0px;
  width: 100%;
}

.card {
  background-color: white;
  margin: 10px;
  overflow-y: auto;
  padding: 20px 50px 80px;
  position: relative;
  width: 70vw;
}

.sectionTitle {
  font-size: 18px;
  font-weight: $font-weight-bold;
  margin: 30px 0 15px;
}

.header {
  display: flex;
  justify-content: center;
  position: relative;
  width: 70vw;
}

.submitButton {
  bottom: 0;
  position: absolute;
  right: 5px;
}

@media screen and (max-width: $sm) {
  .container {
    padding-top: 10px;
  }
  .card {
    padding: 0px 15px 70px;
    width: 98vw;
  }

  .header {
    align-items: center;
    flex-direction: column;
  }

  .submitButton {
    position: static;
    width: 100%;
  }
}
