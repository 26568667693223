.modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 50vh;

  p {
    font-size: 18px;
  }

  img {
    width: 150px;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.button {
  height: 40px;
  margin: 5px 10px;
  width: 150px;
}
