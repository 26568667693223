@import 'src/styles/_variables.module.scss';

.searchCandidate {
  min-width: 200px;
}

.mainContainer {
  margin: auto;
  max-width: $lg;
  padding: 1rem;
}

.tableContainer {
  margin: 2rem 0 0 0;
  width: 100%;
}

.searchbar {
  margin-bottom: 0.5rem;
}

.singleJobDescription {
  display: flex;
  flex-flow: column;
  margin: 1.5rem 0rem 1.5rem 0;
  padding: 1.5rem;
  width: 100%;
  min-height: 15vh;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkboxText {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  color: $black-light;
  height: 20px;
  padding: 16px 0px;
}

@media (min-width: $md) {
  .tableContainer {
    margin: 0 0 0 3rem;
  }
}
