@import 'src/styles/_variables.module';

.kFile {
  display: none;
}

.kFileLabel {
  align-items: center;
  border: 1px solid $primary-color;
  border-radius: 8px;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  padding: 8px;
  width: 233px;

  span {
    color: #3d3d3d;
    font-weight: bold;
    font-size: 24px;
  }
  &:hover {
    background-color: $hover-color;
    color: white;
  }
}

.kFileName {
  color: #4da7b6;
  font-size: 20px;
  margin: 0 12px;
}

.deleteFile {
  color: black;
  cursor: pointer;
  font-size: 20px;
}
