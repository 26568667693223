@import 'src/styles/_variables.module';

.container {
  min-height: 32px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  .root {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
  }

  .option {
    margin-left: 8px;
    color: $font-color;
  }
}

.tab {
  border-radius: 4px;
  justify-content: center;
  padding: 15px;
  background-color: $background-color-tab;
}
