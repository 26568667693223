@import 'src/styles/_variables.module';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}
.card {
  background-color: white;
  margin: 10px;
  min-height: 70vh;
  padding: 20px 20px 70px;
  position: relative;
  width: 70vw;
}

.buttons {
  bottom: 20px;
  position: absolute;
  right: 20px;
}

.button {
  margin-right: 10px;
  width: 150px;
}

@media screen and (max-width: $sm) {
  .container {
    padding-top: 10px;
  }
  .card {
    padding: 0px 0px 70px;
    width: 98vw;
  }
  .button {
    margin-right: 5px;
    width: 80px;
  }
}
