@import 'src/styles/_variables.module.scss';

.page404 {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .container {
    margin: 140px 266px;

    @media screen and (max-width: $md) {
      margin: 80px 2rem;
    }
  }

  .image {
    width: 100%;
  }

  .message {
    max-width: 909px;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
    margin: 0;
  }

  .status {
    width: 72px;
    height: 48px;
    margin-bottom: 64px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #efbdbd;

    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
  }
}
