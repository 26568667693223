@import 'src/styles/_variables.module.scss';

.settings {
  font-size: 48px;
  font-weight: bold;
  line-height: 0.5;
  color: #373737;
  height: 48px;
  margin: 32px 1039px 32px 48px;
}

.mainBox {
  margin: 32px 150px 36px 48px;
  padding: 0 56px 0 32px;
  background-color: #ffffff;
  border-radius: 12px;
  flex-direction: row;
  display: flex;
}

.option {
  cursor: pointer;
}

.option,
.optionSelected {
  height: 24px;
  margin-top: 40px;
  font-size: 20px;
  line-height: 1.2;
  color: #373737;
  display: flex;
  flex-direction: row;
  position: relative;

  &:last-child {
    margin-bottom: 40px;
  }
}

.optionsBox {
  width: 211px;
  border-right: 1px solid #d6d6d6;
}

.personalInformation {
  height: 32px;
  margin-left: 23px;
  margin-top: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #373737;
}

.avatar {
  width: 165px;
  height: 165px;
  margin-left: 23px;
  margin-top: 32px;
  margin-bottom: 64px;
  font-size: 4rem;
  border: solid 1px #373737;
}

.textFieldOutlined {
  width: 360px;
  margin-left: 24px;
  margin-bottom: 32px;
}

.saveButton {
  height: 46px;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: #f0efef;
  font-size: 16px;
  font-weight: bold;
  color: #494949;
}

.changeImage {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 153px;
  left: 148px;
}

.imageWrapper {
  position: relative;
}

.saveButtonWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 72px;
}

.iconWrapper {
  position: absolute;
  top: 1px;
  left: 140px;
  color: #227c8b;
}

.saveButtonActive {
  height: 46px;
  padding: 12px 24px;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #bdd8dc;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #494949;
}

.inputSelect {
  font-size: 20px;
  color: #373737;
}
