@import 'src/styles/_variables.module';

.drawer {
  width: $drawer-width;
  flex-shrink: 0;
  white-space: nowrap;
}

.KSidebar {
  & > div {
    top: unset;
    position: unset;
  }
}

.drawerOpen {
  width: $drawer-width;
  transition: width 0.3s;
}

.drawerClose {
  width: $drawer-width-close-xs;
  transition: width 0.3s;
  overflow-x: hidden;
}

.list {
  padding-top: 0;
}

.sidebarItem {
  padding-top: 12px;
  padding-bottom: 12px;
}

.selected {
  background-color: rgba($color: $primary-color, $alpha: 0.25);
}

@media screen and (min-width: $sm) {
  .drawerClose {
    width: $drawer-width-close-md;
  }

  .toolbar {
    min-height: 64px;
  }
}
