.loadingContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-auto-rows: 50px;
  grid-template-areas:
    '. x x .'
    '. x x .'
    '. y y .';
}

.loadingAnimation {
  grid-area: x;
  align-self: center;
  justify-self: center;
}

.loadingText {
  grid-area: y;
  align-self: center;
  justify-self: center;
}
