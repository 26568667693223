@import 'src/styles/_variables.module';

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  overflow-y: scroll;
  height:93vh;
}
