@import 'src/styles/_variables.module';

.floatingOver {
  position: absolute;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  justify-content: center;
  margin-left: -1vw;
}

.ribbon {
  background-color: $primary-color-login;
  height: 84px;
  width: 100%;
  margin-top: 37vh;
}

.dawoman {
  height: 71vh;
  width: auto;
  margin-right: 24px;
  margin-top: 7vh;
  margin-left: 20px;
}

.slogan {
  height: 64px;
  margin-left: 32px;
}

.header {
  background-color: $primary-color-login;
  height: 88px;
  align-items: center;
  display: flex;
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: $background-container;
  position: relative;
}

.card {
  display: flex;
  justify-content: center;
  height: 50%;
  width: 525px;
  margin: 0px;
  padding: 0px;
  margin-top: 18vh;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 12px;
  margin-right: 20px;
}

.imgWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.input {
  margin-bottom: 16px;
}

.submitWrapper {
  display: flex;
  justify-content: center;
}

.submit {
  width: 283px;
  height: 47px;
  flex-grow: 0;
  border-radius: 4px;
  border: solid 1px #a3a3a3;
  background-color: #ffffff;
  margin-top: 4vh;
}

.submit:hover {
  background-color: #e5f2fd;
}

.linkWrapper {
  text-align: center;
}

.link {
  text-decoration: none;
  color: $primary-color;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}

.welcome {
  font-family: $font-family-login;
  font-size: 48px;
  line-height: 0.83;
  letter-spacing: 0.96px;
  text-align: center;
  color: #325d7f;
  height: 64px;
  margin-top: 10vh;
}

.to {
  font-family: $font-family-login;
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0.8px;
  text-align: center;
  color: #325d7f;
  height: 64px;
}

.kshire {
  height: 64px;
  font-family: $font-family-login;
  font-size: 40px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.8px;
  text-align: center;
  color: #325d7f;
}

.mslogo {
  width: 193px;
  height: 31px;
  flex-grow: 0;
  object-fit: contain;
}

@media (max-height: 600px) {
  .welcome {
    margin-top: 4vh;
  }
}

@media (max-height: 500px) {
  .kshire {
    font-size: 30px;
    height: 48px;
  }
  .to {
    font-size: 30px;
    height: 40px;
  }
  .welcome {
    font-size: 30px;
    height: 40px;
    margin-top: 8vh;
  }
}

@media (max-height: 400px) {
  .kshire {
    font-size: 30px;
    height: 48px;
  }
  .to {
    font-size: 30px;
    height: 40px;
  }
  .welcome {
    font-size: 30px;
    height: 40px;
    margin-top: 4vh;
  }
}

@media (max-width: 640px) {
  .dawoman {
    height: 0px;
    margin: 0px;
  }
  .card {
    margin-left: 20px;
  }
  .floatingOver {
    margin-left: 0px;
  }
}
