@import 'src/styles/_variables.module.scss';

.textContainer {
  min-width: 75vw;
  margin-bottom: 20px;

  @media screen and (max-width: $sm) {
    min-width: 100%;
  }
}
