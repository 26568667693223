.container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-wrap: wrap;
  min-height: 30vh;
}

.roles {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex: 1 1 15rem;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roleDescription {
  flex: 4 1 15rem;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
