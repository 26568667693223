@import 'src/styles/_variables.module';

.uploadButton {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2rem;
  text-decoration: none;
}

.document {
  align-items: center;
  background-color: white;
  border-radius: 8px;
  display: flex;
  height: 150px;
  justify-content: center;
  margin: 5px;
  overflow: hidden;
  position: relative;
  width: 90%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1),
    0px 3px 3px rgba(0, 0, 0, 0.1);
}

.delete {
  align-items: center;
  background-color: $primary-color;
  color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2),
    0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 20px;
  z-index: 1;
  transition: all ease-in-out 0.5s;

  &:hover {
    background-color: $hover-color;
    font-weight: bolder;
  }
}

.documentPreview {
  padding: 5px;
  font-size: 13px;
  text-align: center;
  overflow: hidden;

  svg {
    color: rgb(0, 36, 136);
  }
}

.emptinessBox {
  flex: 1;
}
