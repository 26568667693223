@import 'src/styles/_variables.module.scss';

.filter {
  border-bottom: 2px solid $disabled-color;
  color: $font-color;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
}

.filter:hover {
  color: $primary-color;
}

.filterActive {
  composes: filter;
  background-color: #ebebeb;
  color: $primary-color;
}

.filterName {
  flex: 70%;
  text-align: start;
}

.filterTotal {
  flex: 30%;
  margin-right: 1rem;
}

.filtersList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (min-width: $md) {
  .filterName {
    text-align: end;
  }
}
