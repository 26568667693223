@import 'src/styles/_variables.module.scss';

.candidateApplied {
  height: 100%;
  overflow: auto;
}

.headerImg {
  width: 100%;
}

.candidateAppliedContainer {
  padding: 16px 32px;
}

.bold {
  font-weight: bold;
}
