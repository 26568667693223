@import 'src/styles/_variables.module.scss';

.paper {
  min-width: 75vw;
  padding: 30px;

  @media screen and (max-width: $sm) {
    max-width: 100%;
    padding: 5px;
  }
}

.statsTableHeader {
  margin: 6px;
}

.statsContainer {
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: $sm) {
    flex-wrap: wrap;
  }
}

.singleStat {
  width: 50%;
  padding: 10px;
}

.singleStat:hover {
  width: 50%;
  padding: 10px;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
}

.statNumber {
  font-size: 24px;
  font-weight: bold;
}

.statName {
  font-size: 18px;
  color: $font-color;
  font-weight: bold;
}
