@import 'src/styles/_variables.module.scss';

.fileTypeText {
  align-items: center;
  box-sizing: border-box;
  display: block;
  margin: 4px 0 0 16px;

  p {
    font-size: 12px;
    margin: 0;
  }
}

.checkboxText {
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  padding: 0 0 0 16px;
  span {
    color: black;
    font-size: 16px;
    font-weight: 600;
  }
}

.checkboxLink {
  color: black;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.checkboxLink:visited {
  text-decoration: none;
}

.jobImg {
  width: 25%;
}

.formGridContainer {
  margin: 10px auto;
}

.jobLoadResume {
  margin-bottom: 48px;
  width: 233px;
}

.jobPrivacyPolicyText {
  margin-bottom: 53px;
}

.jobSubmitButton {
  font-size: 24px;
  padding: 12px 16px;
  &:disabled {
    background-color: #d6d6d6;
    color: #3d3d3d;
  }
}

.tooltipContainer {
  width: fit-content;
}
