@import 'src/styles/_variables.module';

.tableCell {
  color: #242528;
  background: none;
}

.tableCellWhiteText {
  font-size: 1.17em;
  font-weight: bold;
  color: $primary-color;
}
