@import 'src/styles/_variables.module.scss';

.detailsContainer {
  margin: 1.5rem 0 0 1rem;
  padding: 0 1rem 0 2rem;

  .detailsList {
    margin-bottom: 1em;

    .detail {
      margin: 1rem 0 0rem;

      .detailText {
        display: flex;
        align-items: center;
      }
      .detailIcon {
        color: rgb(0, 0, 0);
      }

      .detailLink {
        text-decoration: none;
        margin-left: 1rem;
        color: $hover-color;
      }
    }
  }
}

@media (max-width: $md) {
  .detailsContainer {
    padding: 1rem 0rem;
  }
}
