@import 'src/styles/_variables.module';

.privacyPolicy {
  overflow-y: scroll;
  background-color: $ksquare-group-bg;
  justify-content: flex-end;
}

.privacyPolicySidebar {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.selected {
  background-color: $ksquare-group-active-bg;
}

.privacyPolicyTitle {
  margin: unset;
  padding: 14px 0;
}

.privacyImg {
  width: 100%;
}

.privacyContainer {
  padding: 0 24px;
}

.tableOfContents {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15),
    0 2px 3px rgba(0, 0, 0, 0.2);
  width: fit-content;
}

.tableOfContentsContainer {
  padding: 22px 36px;
}

.tableOfContentsTitle {
  font-weight: bold;
  color: $ksquare-group-dark-grey;
  cursor: pointer;
  font-size: 16px;
  padding: 14px;
  margin: unset;
}

.addressLine {
  margin: 8px 0;
  font-weight: bold;
}

.link {
  color: #476fb5;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}
