@import 'src/styles/_variables.module.scss';

.backArrowIcon {
  color: $font-color-header;
  padding: 1rem;
}

.buttonsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.heroHeader {
  background-color: $black-light;
  color: $font-color-header;
  flex-flow: column;
}

.name {
  color: $font-color-header;
  margin: 10px 0;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.iconRef {
  width: 40px;
  height: 20px;
  margin-left: 15px;
  border-radius: 2px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.refText {
  color: black;
  font-weight: $font-weight-extra-bold;
  font-size: 14px;
}

.candidateText {
  color: $font-color-header;
  margin: 0;
}

.locationText {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.offerText {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.optionsIcon {
  color: $font-color-header;
  margin: 0;
  padding: 1rem;
}

.roomIcon {
  margin-right: 0.5rem;
}

.tab {
  font-weight: bold;
}

.candidateInfo {
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  max-width: $lg;
  padding: 0 1rem;
  width: 100%;
}

.tabs {
  align-items: center;
  display: flex;
  flex-flow: column;
  margin: 2rem auto 0 auto;
  max-width: $lg;
  width: 100%;
}

@media (min-width: $md) {
  .candidateInfo {
    align-items: flex-start;
  }

  .tabs {
    flex-flow: row;
    padding: 0 1rem;
  }
}

.timelineContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 228px;
}

// CandidateListing
.PROSPECTIVE {
  color: white;
  background-color: $prospective-stage;
  font-weight: $font-weight-extra-bold;
}
.ACTIVE {
  color: white;
  background-color: $active-stage;
  font-weight: $font-weight-extra-bold;
}
.HIRED {
  color: white;
  background-color: $hired-stage;
  font-weight: $font-weight-extra-bold;
}
.REJECTED {
  color: white;
  background-color: $rejected-stage;
  font-weight: $font-weight-extra-bold;
}
