.versionCollapsible {
    width: 100%;
}

.accordion {
    border-radius: 0% !important;
    box-shadow: none;
    margin-left: 23px;
}

.border {
    border-top: #227c8b 1px solid;
}

.firstBorder {
    border-top: #227c8b 2px solid;
}

.typography {
    color: #227c8b;
}

.typographyDate {
    margin-left: 20px;
}

.expandIcon {
    color: #227c8b;
}