@import 'src/styles/_variables.module.scss';

.timelineDotContainer {
  min-width: 24px;
  min-height: 24px;
  border-radius: 12px;
  background-color: $no-stage;
  display: inline-block;
}

.hasEvent {
  cursor: pointer;
}

.PROSPECTIVE {
  &.CURRENT {
    background-color: $prospective-stage;
  }

  &:hover {
    background-color: #51a3e8;
  }
}

.ACTIVE {
  &.CURRENT {
    background-color: $active-stage;
  }
  &:hover {
    background-color: #3dbece;
  }
}

.HIRED {
  &.CURRENT {
    background-color: $hired-stage;
  }
  &:hover {
    background-color: #6eb573;
  }
}

.REJECTED {
  &.CURRENT {
    background-color: $rejected-stage;
  }
  &:hover {
    background-color: #e86865;
  }
}

.CURRENT:hover {
  cursor: default;
}
