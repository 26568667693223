@import 'src/styles/_variables.module.scss';

.paper {
  min-width: 75vw;
  padding: 30px;

  @media screen and (max-width: $sm) {
    max-width: 100%;
    padding: 5px;
  }
}
.pagination {
  padding: 10px 0;
}

.title {
  @media screen and (max-width: $sm) {
    width: 80%;
    order: 1;
  }
}

.categories {
  @media screen and (max-width: $sm) {
    width: 100%;
    display: flex;
    justify-content: center;
    order: 3;
  }
}

.clearIcon {
  display: flex;
  align-items: center;

  & > svg {
    fill: $font-color;
    cursor: pointer;
  }

  @media screen and (max-width: $sm) {
    width: 20%;
    order: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > div {
      & > button {
        padding: 12px 0px;
      }
    }
  }
}

.emptyNotifications {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.notificationsHeader {
  margin: 6px;
}
.notificationsList {
  margin: 10px 0;
}
.unreadNotification {
  width: 5px;
  height: 95%;
  display: inline-block;
  background: $primary-color;
  position: absolute;
  top: 2.5%;
  left: 0px;
}
