@import 'src/styles/_variables.module.scss';

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
}

.loadingAnimation {
  grid-area: x;
  align-self: center;
  justify-self: center;
}

.loadingText {
  grid-area: y;
  align-self: center;
  justify-self: center;
  font-weight: bold;
}

.job {
  height: 100%;
  overflow-y: auto;
}

.jobContainer {
  padding: 60px 32px 48px;
}

.jobTitle {
  color: #3d3d3d;
  font-size: 36px;
  margin: 64px 0 16px;
}

.jobLocation,
.jobSalary,
.jobTime,
.jobType {
  color: #3d3d3d;
  font-weight: lighter;
  font-size: 20px;
  margin: 0px 0 8px 12px;
}

.jobDescription {
  color: #3d3d3d;
  font-size: 20px;
  margin: 0 auto 96px;
}

.jobStatus {
  border-radius: 4px;
  color: #3d3d3d;
  font-size: 24px;
  font-weight: bold;
  padding: 8px 12px;
  text-transform: uppercase;
  width: fit-content;

  &.closed {
    background-color: #efbdbd;
  }

  &.open {
    background-color: #bdefce;
  }
}

.jobSalaryTitle {
  color: #3d3d3d;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px;
}

.jobAboutUsTitle {
  color: #3d3d3d;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 24px;
}

.jobFormTitle {
  color: #3d3d3d;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 48px;
}

.jobLineDivider {
  background-color: #a3a3a3;
  margin: 47px 0 48px 0;
  width: 100%;
}

.jobListInsights {
  color: #3d3d3d;
  font-size: 20px;
  margin: 0;
  padding-left: 20px;
}

.messageSuccess {
  color: #3d3d3d;
  font-size: 24px;
  font-weight: bold;
  margin-left: 100px;
  @media (max-width: $sm) {
    font-size: 26px;
    margin-left: 0;
  }
}

.messageSuccessOpenJob {
  color: #3d3d3d;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  @media (max-width: $sm) {
    font-size: 26px;
  }
}

.messageSuccessClosedJob {
  color: #3d3d3d;
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  @media (max-width: $sm) {
    font-size: 20px;
  }
}

.checkIcon {
  color: #3ac997;
  font-size: 4rem;
  margin-right: 24px;
  @media (max-width: $sm) {
    font-size: 2rem;
    margin-right: 10px;
  }
}
